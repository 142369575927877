<template>
  <div class="container-fluid">
    <Header />
    <div class="mx-auto mt-1 content-outer-container">
      <div class="wrap-UserLevel">
        <div class="mx-auto content-inner-container border-header">
          <div class="col-12 title-header pl-4">
            <div @click="showModal('top')" class="back-btn mr-3">
              <img src="@/assets/images/userlevel/arrow_back.svg" alt="" />
            </div>
            {{ lbl['user-level-create-member-level'] }}
          </div>
        </div>
        <div class="user-body col-12">
          <div class="body-container">
            <div class="body-main">
              <div class="row">
                <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 mt-4">
                  <!-- box Step 1 -->
                  <div class="box-container">
                    <div class="row">
                      <div class="col-8 title">
                        {{ lbl['user-level-member-level-by'] }}
                      </div>
                      <div class="col-4 align-end">
                        <a @click="editMember(1)">
                          <img
                            src="@/assets/images/userlevel/userlevel_edit.svg"
                            alt=""
                            class="img-edit"
                          />
                          <span class="lbl-edit">{{
                            lbl['user-level-button-edit']
                          }}</span>
                        </a>
                      </div>
                    </div>

                    <div class="row mt-4">
                      <div class="col-12">
                        <div class="main-content view">
                          <ul
                            class="selection-container"
                            v-for="item in selectionChoice"
                            :key="item.id"
                          >
                            <li
                              class="selection-item"
                              :class="{ selected: item.id === dataLevelBy }"
                            >
                              <div class="selection-icon">
                                <img
                                  :src="
                                    require(`../../assets/images/userlevel/${item.img}`)
                                  "
                                  alt=""
                                />
                              </div>
                              <div class="selection-header">
                                {{ item.name }}
                              </div>
                              <div class="selection-desc">{{ item.desc }}</div>
                            </li>
                          </ul>
                        </div>
                        <div class="set-display-conatainer">
                          <div class="title">
                            <strong>
                              {{ lbl['user-level-display-setting-title'] }}
                            </strong>
                            <a-tooltip placement="bottom">
                              <template slot="title">
                                <span>
                                  {{
                                    lbl['user-level-display-setting-tooltip']
                                  }}
                                </span>
                              </template>
                              <a-icon type="info-circle" class="info-icon" />
                            </a-tooltip>
                          </div>
                          <a-switch
                            v-model="displayUserLavel"
                            :checked-children="
                              lbl['user-level-display-setting-title-switch-btn']
                            "
                            :un-checked-children="
                              lbl['user-level-display-setting-title-switch-btn']
                            "
                            default-checked
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- box Step 2 -->
                  <div class="box-container">
                    <div class="row">
                      <div class="col-8 title">
                        {{ lbl['usr-level-manage-tab'] }}
                      </div>
                      <div class="col-4 align-end">
                        <a @click="editMember(2)">
                          <img
                            src="@/assets/images/userlevel/userlevel_edit.svg"
                            class="img-edit"
                            alt=""
                          />
                          <span class="lbl-edit">{{
                            lbl['user-level-button-edit']
                          }}</span>
                        </a>
                      </div>
                    </div>

                    <div class="row mt-4">
                      <div class="col-12">
                        <a-list
                          bordered
                          v-for="(item, index) in dataMemberLevel"
                          :key="index"
                        >
                          <template>
                            <a-row :gutter="[16, 16]">
                              <a-col :span="3">
                                <img
                                  v-if="item.url"
                                  class="size-img"
                                  :src="`${item.url}`"
                                  alt=""
                                />
                              </a-col>
                              <a-col :span="5"
                                ><a-list-item>{{ item.name }}</a-list-item>
                              </a-col>
                              <a-col :span="7">
                                <a-list-item
                                  v-if="index != dataMemberLevel.length - 1"
                                >
                                  {{ stringFormat(item.start) }} -
                                  {{ stringFormat(item.end) }}
                                </a-list-item>
                                <a-list-item
                                  v-else-if="
                                    index == dataMemberLevel.length - 1 &&
                                    item.end != null &&
                                    item.end != '' &&
                                    item.end != 0
                                  "
                                >
                                  {{ stringFormat(item.start) }} -
                                  {{ stringFormat(item.end) }}
                                </a-list-item>
                                <a-list-item v-else>
                                  {{ lbl['user-level-more-than'] }}
                                  {{ stringFormat(item.start) }}
                                  {{
                                    dataUserLevel.levelBy == 1
                                      ? lbl['user-level-point-unit']
                                      : lbl['user-level-number-baht']
                                  }}
                                </a-list-item>
                              </a-col>
                            </a-row>
                          </template>
                        </a-list>
                      </div>
                    </div>
                  </div>

                  <!-- box Step 3 -->
                  <div class="box-container">
                    <div class="row">
                      <div class="col-8 title">
                        {{ lbl['user-level-condition'] }}
                      </div>
                      <div class="col-4 align-end">
                        <a @click="editMember(3)">
                          <img
                            src="@/assets/images/userlevel/userlevel_edit.svg"
                            class="img-edit"
                            alt=""
                          />
                          <span class="lbl-edit">{{
                            lbl['user-level-button-edit']
                          }}</span>
                        </a>
                      </div>
                    </div>

                    <div class="row mt-4">
                      <div class="col-12">
                        <div>
                          <span class="condition-title"
                            >{{ lbl['user-level-member-level-start'] }} :
                          </span>
                          {{ dateFormat(dataLevelSetting.programDate) }}
                        </div>
                        <div class="mt-4">
                          <span class="condition-title"
                            >{{ lbl['edit-date'] }} :
                          </span>
                          {{ dataUpdateDate }}
                        </div>
                        <div class="mt-4">
                          <span class="condition-title"
                            >{{ lbl['user-level-member-level-setting'] }} :
                          </span>
                          <span class="condition-value">{{
                            dataLevelSetting.selectedProgram
                          }}</span>
                          <span
                            v-if="dataLevelSetting.selectedProgram == 'advance'"
                          >
                            {{ lbl['user-level-member-tier-detail'] }}
                          </span>
                          <span v-else>
                            {{ lbl['user-level-reset-period'] }}
                          </span>
                        </div>
                        <div class="img-selected-program mt-4">
                          <img
                            v-if="dataLevelSetting.selectedProgram == 'advance'"
                            class="img-selected-program"
                            src="@/assets/images/userlevel/advance_en@3x.png"
                            alt=""
                          />
                          <img
                            v-else
                            class="img-selected-program"
                            src="@/assets/images/userlevel/basic_en@3x.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- box Step 4 -->
                  <div class="box-container">
                    <div class="row">
                      <div class="col-8 title">
                        {{ lbl['user-level-preview-details'] }}
                      </div>
                      <div class="col-4 align-end">
                        <a @click="editMember(4)">
                          <img
                            src="@/assets/images/userlevel/userlevel_edit.svg"
                            alt=""
                            class="img-edit"
                          />
                          <span class="lbl-edit">{{
                            lbl['user-level-button-edit']
                          }}</span>
                        </a>
                      </div>
                    </div>

                    <div class="row mt-4">
                      <div class="col-12">
                        <div class="content-main">
                          <div class="badge-category">
                            <ul
                              class="badge-selector"
                              v-for="(item, index) in dataMemberLevel"
                              :key="index"
                              :class="{ selected: selectBadge == index }"
                            >
                              <li
                                @click="clickBadge(index, item)"
                                class="badge-item"
                              >
                                <img
                                  v-if="item.url"
                                  class="badge-image"
                                  :src="`${item.url}`"
                                  alt=""
                                />
                                <h2 class="badge-desc">{{ item.name }}</h2>
                              </li>
                            </ul>
                          </div>
                          <div
                            v-html="selectDataPreview(selectBadge, 'data')"
                            class="inside-preview mb-0"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <div class="preview-main">
                    <div class="preview-header">
                      {{ lbl['user-level-preview'] }}
                    </div>
                  </div>
                  <div class="d-flex justify-content-center">
                    <div class="previewContainer">
                      <img
                        src="@/assets/images/userlevel/img_mobile.png"
                        class="img-mobile"
                        alt="img"
                      />
                      <div class="content-item">
                        <div class="box-item">
                          <div class="pb-2 pt-1 myprofile-title">
                            {{ lbl['user-level-point-myprofile'] }}
                          </div>
                          <div
                            id="card_with_user_level"
                            v-if="displayUserLavel"
                            class="mainCard"
                          >
                            <div class="nameContainer">
                              <div class="level">
                                <img
                                  src="@/assets/images/userlevel/Star_Platinum.svg"
                                  alt="userlevel"
                                  width="30"
                                  height="30"
                                />
                              </div>
                              <div class="name">Thomas Shelby</div>
                            </div>
                            <div class="phone">08x-xxx-xxxx</div>
                            <div class="pointYouHave">
                              {{ lbl['user-level-point-name'] }}
                              <span class="point">2,000</span>
                              {{
                                dataUserLevel.levelBy == 1
                                  ? lbl['user-level-number-point']
                                  : lbl['user-level-number-baht']
                              }}
                            </div>
                            <div class="progressBarContainer">
                              <div class="progressBar">
                                <div class="pgbar"></div>
                              </div>
                              <div
                                v-if="dataMemberLevel.length == 1"
                                class="level"
                              >
                                <img
                                  class="profile__image"
                                  src="@/assets/images/userlevel/Star_Platinum.svg"
                                  alt="Avatar"
                                  width="30"
                                  height="30"
                                />
                                <div class="icon-name">Standard</div>
                              </div>
                              <div v-else class="level">
                                <img
                                  class="profile__image"
                                  src="@/assets/images/userlevel/Star_Gold.svg"
                                  alt="Avatar"
                                  width="30"
                                  height="30"
                                />
                                <div class="icon-name">Gold</div>
                              </div>
                            </div>
                            <div class="detail">
                              20
                              {{
                                dataUserLevel.levelBy == 1
                                  ? lbl['user-level-point-expired']
                                  : lbl['user-level-baht-expired']
                              }}
                              30-04-2022
                            </div>
                          </div>
                          <div
                            id="card_without_user_level"
                            v-if="!displayUserLavel"
                            class="mainCard mb-3"
                          >
                            <div class="nameContainer">
                              <div class="level">
                                <img
                                  src="@/assets/images/User.svg"
                                  alt="user_profile"
                                  width="30"
                                  height="30"
                                />
                              </div>
                              <div class="name">Thomas Shelby</div>
                            </div>
                            <div class="phone">08x-xxx-xxxx</div>
                            <div class="pointYouHave">
                              {{ lbl['user-level-not-show-point-name'] }}
                              <span class="point">2,000</span>
                              {{
                                dataUserLevel.levelBy == 1
                                  ? lbl['user-level-number-point']
                                  : lbl['user-level-number-baht']
                              }}
                            </div>
                          </div>
                          <div class="preview-body">
                            <div v-if="displayUserLavel" class="preview-scorll">
                              <div class="preview-category">
                                <ul
                                  class="preview-selector"
                                  v-for="(item, index) in dataMemberLevel"
                                  :key="index"
                                  :class="{ selected: selectPreview == index }"
                                >
                                  <li
                                    @click="clickPreview(index)"
                                    class="badge-item"
                                  >
                                    <img
                                      v-if="item.url"
                                      class="badge-image"
                                      :src="`${item.url}`"
                                      alt=""
                                    />
                                    <h2 class="badge-desc">{{ item.name }}</h2>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div
                              v-if="
                                dataMemberLevel != undefined &&
                                dataMemberLevel != null &&
                                displayUserLavel
                              "
                              class="preview-detail"
                            >
                              <span>{{
                                lbl['user-level-preview-details']
                              }}</span>
                              <div
                                v-html="
                                  selectDataPreview(selectPreview, 'data')
                                "
                                class="inside-preview mb-0"
                                :class="{
                                  seemore: selectPreview === clickSeemore,
                                }"
                              ></div>
                              <a
                                v-if="
                                  selectDataPreview(selectPreview, 'data') !=
                                    '' &&
                                  selectDataPreview(selectPreview, 'data') !=
                                    null &&
                                  selectPreview != clickSeemore
                                "
                                href=""
                                class="btn-seemore"
                                @click="handleSeemore"
                                >{{ lbl['user-level-preview-see-more'] }}</a
                              >
                            </div>
                            <div class="wrapper-other">
                              <div class="row py-1 bg-white">
                                <div class="col-8 clickto">
                                  {{ lbl['user-level-point-myprofile'] }}
                                </div>
                                <div class="col-4 icon-click">
                                  <a-icon type="right" />
                                </div>
                              </div>
                              <div class="row py-1 bg-white">
                                <div class="col-8 clickto">
                                  {{ lbl['user-level-preview-address-name'] }}
                                </div>
                                <div class="col-4 icon-click">
                                  <a-icon type="right" />
                                </div>
                              </div>
                              <div class="row py-1 bg-white">
                                <div class="col-8 clickto">
                                  {{ lbl['user-level-preview-history-name'] }}
                                </div>
                                <div class="col-4 icon-click">
                                  <a-icon type="right" />
                                </div>
                              </div>
                              <div class="row py-1 bg-white">
                                <div class="col-8 clickto">
                                  {{
                                    lbl[
                                      'user-level-preview-privacy-consent-name'
                                    ]
                                  }}
                                </div>
                                <div class="col-4 icon-click">
                                  <a-icon type="right" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="user-footer col-12 end">
          <b-button
            variant="outline-primary"
            class="userlevel-btn outline"
            @click="showModal('button')"
          >
            {{ lbl['user-level-button-cancel'] }}
          </b-button>
          <b-button
            variant="warning"
            class="userlevel-btn"
            @click="handleSubmit()"
          >
            {{ lbl['user-level-button-save'] }}
          </b-button>
        </div>
      </div>
    </div>
    <ModalCancle />
    <AlertModal
      :isOpenModal="alertModalSetting.isOpenModal"
      :modalType="alertModalSetting.modalType"
      :title="alertModalSetting.title"
      :details="alertModalSetting.details"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import Account from '@/helper/AccountHelper.js'
import Mixin from '@/mixin/Mixin'
import Header from '@/components/Layout/Header'
import ModalCancle from '@/module/UserProfile/modal/CancleModal.vue'
import moment from 'moment'
import BzbsUserProfile from '@/core/UserProfile/service/BzbsUserProfile'
import AlertModal from '@/components/Modal/Component/alertmodal2'
import Locale from '@/helper/locale.js'

export default {
  name: 'StepOne',
  components: {
    Header,
    ModalCancle,
    AlertModal,
  },
  mixins: [Mixin],
  data: function () {
    return {
      currenttitle: null,
      current: null,
      parent: null,
      dataLevelBy: null,
      displayUserLavel: true,
      dataLevelSetting: {
        programDate: new Date(),
        selectedProgram: 'basic',
      },
      dataMemberLevel: [{ index: null }],
      selectPreview: 0,
      clickSeemore: null,
      selectionChoice: [],
      selectBadge: 0,
      isEdit: false,
      isUpdate: false,
      alertModalSetting: {
        title: '',
        detail: '',
        modalType: '',
        isOpenModal: false,
      },
      dataUpdateDate: '-',
    }
  },
  created() {
    this.handleFooter(true)
    this.handleLoading(false)
    Account.bzbsAnalyticTracking(
      'member_level_step_preview',
      'member_level',
      'view_member_level_step_preview',
      'on view',
    )
    this.init()
  },
  methods: {
    showModal(position) {
      if (position == 'button') {
        Account.bzbsAnalyticTracking(
          'member_level_step_preview',
          'member_level',
          'click_member_level_step_cancel',
          'on click',
        )
      } else {
        Account.bzbsAnalyticTracking(
          'member_level_step_preview',
          'member_level',
          'click_member_level_preview_exit',
          'on click',
        )
      }
      this.$bvModal.show('modalCancle')
    },
    alertModalAction(title, details, modalType, isOpenModal) {
      this.alertModalSetting.title = title
      this.alertModalSetting.details = details
      this.alertModalSetting.modalType = modalType
      this.alertModalSetting.isOpenModal = isOpenModal
      if (modalType == 'success' && isOpenModal) {
        setTimeout(() => {
          this.$router.replace({ name: 'CreateUserLevel' })
        }, 3000)
      } else {
        if (isOpenModal)
          setTimeout(() => {
            this.alertModalSetting.isOpenModal = false
          }, 3000)
      }
    },
    init() {
      this.currenttitle = this.lbl['user-level-member-level']
      this.current = this.lbl['user-level-create-member-level']

      this.selectionChoice = [
        {
          id: '1',
          name: this.lbl['user-level-earning-point'],
          desc: this.lbl['user-level-point-hunter'],
          img: 'loupe.svg',
        },
        {
          id: '2',
          name: this.lbl['user-level-total-spending'],
          desc: this.lbl['user-level-most-spending-win'],
          img: 'brightness_low.svg',
        },
      ]
      this.dataUserLevel = JSON.parse(localStorage.getItem('dataUserLevel'))
      if (this.dataUserLevel != undefined) {
        if (this.dataUserLevel.isEdit && this.dataUserLevel.isUpdate == false) {
          if (this.dataUserLevel.isEdit != undefined) {
            this.isEdit = this.dataUserLevel.isEdit
          } else {
            this.isEdit = false
          }
          if (this.dataUserLevel.isUpdate != undefined) {
            this.isUpdate = this.dataUserLevel.isUpdate
          } else {
            this.isUpdate = false
          }
          this.getDataInfo()
        } else {
          if (this.dataUserLevel.isEdit != undefined) {
            this.isEdit = this.dataUserLevel.isEdit
          } else {
            this.isEdit = false
          }
          if (this.dataUserLevel.isUpdate != undefined) {
            this.isUpdate = this.dataUserLevel.isUpdate
          } else {
            this.isUpdate = false
          }
          if (this.dataUserLevel.levelBy != undefined) {
            this.dataLevelBy = this.dataUserLevel.levelBy
          }
          if (this.dataUserLevel.displayUserLavel != undefined) {
            this.displayUserLavel = this.dataUserLevel.displayUserLavel
          }
          if (this.dataUserLevel.levelSetting != undefined) {
            this.dataLevelSetting = this.dataUserLevel.levelSetting
          }
          if (this.dataUserLevel.memberLevel != undefined) {
            this.dataMemberLevel = Object.entries(
              this.dataUserLevel.memberLevel,
            ).map(arr => {
              return arr[1]
            })
          }
        }
      } else {
        this.$router.push({
          name: 'CreateUserLevel',
        })
      }
    },
    getDataInfo() {
      return new Promise(resolve => {
        BzbsUserProfile.getLevelList()
          .then(res => {
            if (res != null) {
              this.genDataInfo(res.data)
            } else {
              this.isCreate = false
            }
            resolve(res.data)
          })
          .catch(error => {
            this.isCreate = false
            console.log('getLevelList error', error)
            resolve(error)
          })
      })
    },
    genDataInfo(data) {
      console.log('genDataInfo : ', data)
      if (data != null) {
        this.dataUserLevel.isActive = false

        if (
          data[0]['LevelStatus'].toLowerCase() == 'active' &&
          data[0]['Active']
        ) {
          this.dataUserLevel.isActive = true
        }
        if (data[0].Mode == 'spending') {
          this.dataLevelBy = '2'
        } else {
          this.dataLevelBy = '1'
        }

        if (data[0].UpdateDate) {
          this.dataUpdateDate = moment
            .unix(data[0].UpdateDate)
            .format('DD/MM/yyyy')
        } else {
          this.dataUpdateDate = '-'
        }
        if (data[0].HideLevelOnConsumerView) {
          this.displayUserLavel = !data[0].HideLevelOnConsumerView
        }

        let locale = Locale.getLocaleCode()
        if (locale != 'th') locale = 'en'
        moment.locale(locale)

        this.dataLevelSetting = {
          programDate: moment.unix(data[0].StartDate).toDate(),
          selectedProgram: data[0].LevelType,
        }

        this.dataMemberLevel = _.map(data, (e, i) => {
          return _.extend({
            index: i + 1,
            name: e.UserLevelName,
            start: e.Value,
            end: e.MaxValue,
            detail: e.Description,
            url: e.UserLevelIconUrl,
            error: false,
          })
        })

        this.dataUserLevel.levelBy = this.dataLevelBy
        this.dataUserLevel.displayUserLavel = this.displayUserLavel
        this.dataUserLevel.levelSetting = this.dataLevelSetting
        this.dataUserLevel.memberLevel = this.dataMemberLevel

        console.log('dataUserLevel result : ', this.dataUserLevel)
      }
    },
    clickPreview(index) {
      this.selectPreview = index
    },
    selectDataPreview(index, type) {
      if (type == 'data') {
        return this.dataMemberLevel[index].detail
      } else {
        return this.dataMemberLevel[index].index
      }
    },
    handleSeemore(e) {
      e.preventDefault()
      this.clickSeemore = this.selectPreview
    },
    dateFormat(date) {
      return moment(date).format('DD/MM/yyyy')
    },
    stringFormat(value) {
      if (String(value).length > 3) {
        const result = String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        return result
      } else {
        return String(value)
      }
    },
    clickBadge(index) {
      console.log('clickBadge : ', index)
      this.selectBadge = index
      this.dataMemberLevel[index].index = index
    },
    editMember(step) {
      console.log('editMember', step)
      if (step == 2) {
        Account.bzbsAnalyticTracking(
          'member_level_step_preview',
          'member_level',
          'click_member_level_preview_step2',
          'on click',
        )
        localStorage.setItem(
          'dataUserLevel',
          JSON.stringify(this.dataUserLevel),
        )
        this.$router.replace({
          name: 'StepTwo',
        })
      } else if (step == 3) {
        Account.bzbsAnalyticTracking(
          'member_level_step_preview',
          'member_level',
          'click_member_level_preview_step3',
          'on click',
        )
        localStorage.setItem(
          'dataUserLevel',
          JSON.stringify(this.dataUserLevel),
        )
        this.$router.replace({
          name: 'StepThree',
        })
      } else if (step == 4) {
        Account.bzbsAnalyticTracking(
          'member_level_step_preview',
          'member_level',
          'click_member_level_preview_step4',
          'on click',
        )
        localStorage.setItem(
          'dataUserLevel',
          JSON.stringify(this.dataUserLevel),
        )
        this.$router.replace({
          name: 'StepFour',
        })
      } else {
        Account.bzbsAnalyticTracking(
          'member_level_step_preview',
          'member_level',
          'click_member_level_preview_step1',
          'on click',
        )
        localStorage.setItem(
          'dataUserLevel',
          JSON.stringify(this.dataUserLevel),
        )
        this.$router.replace({
          name: 'StepOne',
        })
      }
    },
    checkMinDate(date) {
      var today = new Date()
      var today_date = today.getDate()
      var today_month = today.getMonth() + 1
      var today_year = today.getFullYear()

      var date_check = moment(date).toDate()
      var check_date = date_check.getDate()
      var check_month = date_check.getMonth() + 1
      var check_year = date_check.getFullYear()

      if (check_year == today_year) {
        if (check_month == today_month) {
          if (check_date <= today_date) {
            return true
          } else {
            return false
          }
        } else if (check_month < today_month) {
          return true
        } else {
          return false
        }
      } else if (check_year < today_year) {
        return true
      } else {
        return false
      }
    },
    handleSubmit() {
      console.log('handleSubmit')
      Account.bzbsAnalyticTracking(
        'member_level_step_preview',
        'member_level',
        'click_member_level_step_save',
        'on click',
      )
      let LevelBy = ''
      if (this.dataLevelBy != null) {
        if (this.dataLevelBy == '2') {
          LevelBy = 'spending'
        } else {
          LevelBy = 'point'
        }
      }

      var startDate = ''
      if (this.dataLevelSetting.programDate != undefined) {
        var strDate = moment(this.dataLevelSetting.programDate).format('MM-DD')
        var year = moment(this.dataLevelSetting.programDate).format('YYYY')
        startDate = year + '-' + strDate
      }

      var levelType = 'basic'
      if (this.dataLevelSetting.selectedProgram != undefined) {
        levelType = this.dataLevelSetting.selectedProgram
      } else {
        levelType = 'basic'
      }

      if (this.isEdit == true) {
        var strUpdateDate = moment(new Date()).format('MM-DD')
        var yearUpdateDate = moment(new Date()).format('YYYY')
        var updateDate = yearUpdateDate + '-' + strUpdateDate
        const value = _.map(this.dataMemberLevel, e => {
          return _.extend({
            levelname: e.name,
            description: e.detail,
            mode: LevelBy,
            startdate: startDate,
            level_icon_url: e.url,
            value: e.start.toString(),
            max_value: e.end ? e.end.toString() : null,
            leveltype: levelType,
            pointrate: '20',
            expiredate: '',
            level_startdate: '',
            level_expiredate: '',
            condition: '',
            updatedate: updateDate,
            hideleveloncusumerview: !this.displayUserLavel,
          })
        })
        return new Promise(resolve => {
          BzbsUserProfile.getLevelList()
            .then(res => {
              if (res != null) {
                //cancel publish user level
                if (res.data != undefined) {
                  if (res.data[0] != undefined) {
                    if (res.data[0]['Active']) {
                      BzbsUserProfile.postCancelPublishgroup(
                        res.data[0]['GroupCreate'],
                      )
                        .then(res => {
                          console.log('postCancelPublishgroup success', res)
                          let strGroupkey = res.data[0]['GroupCreate']
                          if (strGroupkey) {
                            BzbsUserProfile.postUpdateLevel(value, strGroupkey)
                              .then(res => {
                                if (res != null) {
                                  if (res.data.length > 0) {
                                    //publish user level
                                    BzbsUserProfile.postPublishgroup(
                                      res.data[0]['GroupCreate'],
                                    )
                                      .then(res => {
                                        console.log(
                                          'postPublishgroup success',
                                          res,
                                        )
                                      })
                                      .catch(error => {
                                        console.log(
                                          'postPublishgroup error',
                                          error,
                                        )
                                      })

                                    this.alertModalAction(
                                      this.lbl['alert-box-success-header'],
                                      this.lbl[
                                        'alert-box-success-waiting-15-minutes-message'
                                      ],
                                      'success',
                                      true,
                                    )
                                  }
                                }
                                resolve(res.data)
                              })
                              .catch(error => {
                                console.log('postUpdateLevel error', error)
                                resolve(error)
                              })
                          }
                        })
                        .catch(error => {
                          console.log('postCancelPublishgroup error', error)
                        })
                    } else {
                      let strGroupkey = res.data[0]['GroupCreate']
                      if (strGroupkey) {
                        BzbsUserProfile.postUpdateLevel(value, strGroupkey)
                          .then(res => {
                            if (res != null) {
                              if (res.data.length > 0) {
                                //publish user level
                                BzbsUserProfile.postPublishgroup(
                                  res.data[0]['GroupCreate'],
                                )
                                  .then(res => {
                                    console.log('postPublishgroup success', res)
                                  })
                                  .catch(error => {
                                    console.log('postPublishgroup error', error)
                                  })

                                this.alertModalAction(
                                  this.lbl['alert-box-success-header'],
                                  this.lbl[
                                    'alert-box-success-waiting-15-minutes-message'
                                  ],
                                  'success',
                                  true,
                                )
                              }
                            }
                            resolve(res.data)
                          })
                          .catch(error => {
                            console.log('postUpdateLevel error', error)
                            resolve(error)
                          })
                      }
                    }
                  }
                }
              }
            })
            .catch(error => {
              console.log('getLevelList error', error)
            })
        })
      } else {
        const value = _.map(this.dataMemberLevel, e => {
          return _.extend({
            levelname: e.name,
            description: e.detail,
            mode: LevelBy,
            startdate: startDate,
            level_icon_url: e.url,
            value: e.start.toString(),
            max_value: e.end ? e.end.toString() : null,
            leveltype: levelType,
            pointrate: '20',
            expiredate: '',
            level_startdate: '',
            level_expiredate: '',
            condition: '',
            hideleveloncusumerview: !this.displayUserLavel,
          })
        })
        return new Promise(resolve => {
          BzbsUserProfile.postCreateLevel(value)
            .then(res => {
              if (res != null) {
                if (res.data.length > 0) {
                  //publish user level
                  BzbsUserProfile.postPublishgroup(res.data[0]['GroupCreate'])
                    .then(res => {
                      console.log('postPublishgroup success', res)
                    })
                    .catch(error => {
                      console.log('postPublishgroup error', error)
                    })

                  this.alertModalAction(
                    this.lbl['alert-box-success-header'],
                    this.lbl['alert-box-success-waiting-15-minutes-message'],
                    'success',
                    true,
                  )
                }
              }
              resolve(res.data)
            })
            .catch(error => {
              console.log('postCreateLevel error', error)
              resolve(error)
            })
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/module/UserProfile/UI/BaseStyle.scss';
ul,
li {
  padding: 0;
  margin: 0;
}
.user-body {
  width: 100%;
  .body-container {
    width: 100%;
    .body-main {
      margin: 0 25px;
      .content-main {
        .badge-category {
          display: flex;
          margin: 15px 0;
          overflow-y: auto;
          .badge-selector {
            display: flex;
            list-style: none;
            border: solid 1px #bdbdbd;
            background-color: #f5f5f5;
            border-radius: 40px;
            padding: 2px 8px;
            margin-right: 10px;
            cursor: pointer;
            &.selected {
              border: solid 2px #616161;
              background-color: #ffffff;
              box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
            }
            .badge-item {
              display: flex;
              align-items: center;
              justify-content: center;
              .badge-image {
                height: 25px;
                margin-right: 5px;
              }
              .badge-desc {
                font-size: 0.9rem;
                margin: 0;
              }
            }
          }
        }
        .editer-container {
          width: 95%;
        }

        .fill-red {
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: 0.15px;
          text-align: left;
          color: #ff5252;
        }
      }
      .preview-main {
        .preview-header {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: 0.15px;
          text-align: left;
          color: #424242;
        }
      }
    }
  }
}
.img-selected-program {
  width: 100%;
}
</style>
