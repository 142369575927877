<template>
  <b-modal
    id="modalCancle"
    modal-class="modal-list"
    centered
    hide-header
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    :modalClass="[currentLocale]"
  >
    <div class="modal-base">
      <div class="modal-header">
        <div class="image-container">
          <img src="@/assets/images/userlevel/warning.svg" alt="" />
        </div>
        <h3>{{ lbl['user-level-leave-withour-saving'] }}</h3>
      </div>
      <div class="modal-desc">
        <p>
          {{ lbl['user-level-leave-withour-saving-detail'] }}
        </p>
      </div>
    </div>
    <div class="modal-base-footer">
      <button class="btn-close" @click="$bvModal.hide('modalCancle')">
        {{ lbl['user-level-leave-btn-back'] }}
      </button>
      <button @click="goto('CreateUserLevel')" class="btn-submit">
        {{ lbl['user-level-leave-btn-yes'] }}
      </button>
    </div>
  </b-modal>
</template>

<script>
// mixin
import Mixin from '@/mixin/Mixin'
import Locale from '@/helper/locale.js'

export default {
  name: 'ModalCancle',
  mixins: [Mixin],
  data: function () {
    return {
      currentLocale: Locale.getLocaleShort(),
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .modal-content {
  border-radius: 0;

  .modal-body {
    .modal-base {
      .modal-header {
        justify-content: flex-start;
        border: none;
        .image-container {
          img {
            margin-right: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        h3 {
          font-size: 1.25rem;
        }
      }
      .modal-desc {
        padding: 0 14.4px 15px;
      }
    }
    .modal-base-footer {
      display: flex;
      justify-content: flex-end;
      button {
        padding: 8px 16px;
      }
      .btn-close {
        background-color: #fff;
        border: solid 1px #d9d9d9;
        border-radius: 4px;
        margin-right: 8px;
      }
      .btn-submit {
        background-color: #ff9800;
        color: #fff;
        border: none;
        border-radius: 4px;
      }
    }
  }
}
</style>